<template>
  <v-dialog style="max-width: 25% !important;" :fullscreen="!isBig ? true : false" v-model="dialog" max-width="700"
    @click:outside="emitOutside()" elevation="0" @input="handleDialogOpen()">
    <v-toolbar dark color="#ee7317" v-if="!isBig">
      <v-toolbar-title>Denúncia</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="emitOutside()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div :class="['dialog-content', { 'loading': isLoadingDiv }]">
      <!-- FLECHA -->
      <transition name="fade">
        <div v-if="isBig" :class="[isMarkerBreak ? arrowheadBg : arrowheadMd]"></div>
      </transition>

      <!-- Your dialog content here -->
      <v-card :class="[isBig ? cardBig : cardMobile]">
        <div v-if="!isLoading">

          <!-- IMAGE -->
          <div class="img-container">
            <v-img :aspect-ratio="5 / 4" style="max-height: 450px;" v-if="dialogContent.photos.length == 1"
              :src="getPhoto()">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="blue"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-carousel style="max-height: 450px;" v-else cycle hide-delimiter-background>
              <v-carousel-item v-for="(slide, i) in dialogContent.photos" :key="i">
                <v-img class="img-marker" :src="slide.url" width="100%" :aspect-ratio="5 / 4" />
              </v-carousel-item>
            </v-carousel>
          </div>

          <hr class="dialog-divider">

          <v-card-subtitle class="pb-0">

            <v-subheader>Denúncia</v-subheader>

            <div style="display: inline-flex;padding-left: 4px">
              <v-img class="img-icon" :src="getSrcElement" alt="elemento denúncia"></v-img>
              <v-card-title class="pl-0 pb-0 pt-0"
                style="padding-left:8px !important; padding-right:0px !important;color:#ee7317 ">{{
                  dialogContent.elements
                }}</v-card-title>
            </div>

            <div style="display: flex;padding-left: 4px;">
              <v-icon color="#ee7317">mdi-map-marker</v-icon>
              <v-subheader class="pl-1">
                {{ dialogContent.lat }} , {{ dialogContent.lng }}
              </v-subheader>
            </div>

            <div style="display: inline-flex;padding-left: 4px">
              <v-img class="img-icon" :src="getSrcCategoria" alt="categoria denúncia"></v-img>
              <span class="categorie-txt">{{ dialogContent.categorie }}</span>
            </div>

            <v-divider class="mt-4"></v-divider>
            <div v-if="dialogContent.relato">
              <v-subheader> Relato
              </v-subheader>
              <div class="text--body-2 py-2 px-8">{{ dialogContent.relato }}</div>
            </div>
          </v-card-subtitle>
          <v-card-subtitle class="pb-0">
            <v-subheader style="height: 20px !important;">Impactos</v-subheader>
            <v-list class="px-4" dense style="background-color: transparent !important;">
              <v-list-item v-for="(impact, i) in dialogContent.impacts" :key="i">
                {{ impact }}
              </v-list-item>
            </v-list>
          </v-card-subtitle>
          <v-divider class="mt-2 mx-4"></v-divider>
          <div style="display: flex;justify-content:space-between;align-items: center;padding:10px 4px">

            <div class="text--subtitle pl-3" style="font-size: 12px; color: rgba(0, 0, 0, 0.8);">
              <v-icon small>mdi-clock-outline</v-icon> Registrado em {{ dialogContent.createdAt }}
            </div>

            <div>
              <v-btn color="#ee7317" large style="position: relative;right:8px;color: white;"
                @click="copyClipboard">Compartilhar<v-icon class="pl-2">mdi-share-variant</v-icon></v-btn>
            </div>
          </div>
        </div>
        <div v-else>
          <v-skeleton-loader style="padding:15px;width:500px;height:500px" type="card, article"></v-skeleton-loader>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import clipboard from 'clipboard-copy';
import tabela from '@/mixin.js'

export default {
  name: 'MarkerDialog',
  mixins: [tabela],
  data() {
    return {
      iconArea: require('@/assets/icons/subicons/c-fundeio.png'),
      iconRotas: require('@/assets/icons/subicons/c-rotas.png'),
      iconEstrutura: require('@/assets/icons/subicons/c-apoio.png'),
      iconMancha: require('@/assets/icons/subicons/c-manchaorfa.png'),
      cardBig: 'card-big',
      cardMobile: 'card-mobile',
      msg: "adsdas",
      isLoadingDiv: false,
      arrowheadBg: 'arrowhead-big',
      arrowheadMd: 'arrowhead-md',
      windowWidth: window.innerWidth,
      cardHeight: 'card-100',
      url: process.env.VUE_APP_LOCAL_URL,
      elementosAreasArray: [
        `${require('@/assets/icons/subicons/d-emb-grandeporte.png')}`,
        `${require('@/assets/icons/subicons/d-navio-petroleo-gas.png')}`,
        `${require('@/assets/icons/subicons/d-offshore.png')}`,
        `${require('@/assets/icons/subicons/d-rebocadores.png')}`,
        `${require('@/assets/icons/subicons/d-naufragio.png')}`
      ],
      elementosRotasArray: [
        `${require('@/assets/icons/subicons/d-boias.png')}`,
        `${require('@/assets/icons/subicons/d-sinal.png')}`,
        `${require('@/assets/icons/subicons/d-dragagem.png')}`,
      ],
      elementosEstruturasArray: [
        `${require('@/assets/icons/subicons/d-estaleiro.png')}`,
        `${require('@/assets/icons/subicons/d-dutos-off.png')}`,
        `${require('@/assets/icons/subicons/d-terrestres.png')}`,
        `${require('@/assets/icons/subicons/d-petroleo-gas.png')}`,
        `${require('@/assets/icons/subicons/d-plataforma.png')}`,
        `${require('@/assets/icons/subicons/d-refinarias.png')}`,
        `${require('@/assets/icons/subicons/d-portuario.png')}`,
        `${require('@/assets/icons/subicons/d-derivados.png')}`,
        `${require('@/assets/icons/subicons/d-manutencao.png')}`,
      ],
    }
  },
  computed: {
    getSrcCategoria() {
      if (!this.dialogContent) {
        return ' '
      }
      switch (this.dialogContent.categorie) {
        case this.tabela.categorias[0]:
          return this.url + this.iconArea
        case this.tabela.categorias[1]:
          return this.url + this.iconRotas;
        case this.tabela.categorias[2]:
          return this.url + this.iconEstrutura;
        case this.tabela.categorias[3]:
          return this.url + this.iconMancha;
        default:
          return ''
      }
    },
    getSrcElement() {
      if (!this.dialogContent) {
        return ' '
      }
      const categoriaAtual = this.dialogContent.categorie;
      const elementoAtual = this.dialogContent.elements;
      // EX. EMBARACAO APOIO OFFSHORE

      switch (categoriaAtual) {
        case this.tabela.categorias[0]:
          {
            const index = this.tabela.elementosAreas.indexOf(elementoAtual);
            return this.url + this.elementosAreasArray[index];
          }
        case this.tabela.categorias[1]:
          {
            const index = this.tabela.elementosRotas.indexOf(elementoAtual);
            return this.url + this.elementosRotasArray[index];
          }
        case this.tabela.categorias[2]:
          {
            const index = this.tabela.elementosEstruturas.indexOf(elementoAtual);
            return this.url + this.elementosEstruturasArray[index];
          }
        case this.tabela.categorias[3]:
          return this.url + this.iconMancha;
      }
      return ''
    },
    getElementsSrc() {
      const element = this.dialogContent.elements;
      return this.url + this.elementIcons[element];
    },
    isBig() {
      return this.$vuetify.breakpoint.mdAndUp ? true : false
    },
    isMarkerBreak() {
      return this.windowWidth > 1540;
    }
  },
  methods: {
    async copyClipboard() {
      const url = window.location.href;
      try {
        await clipboard(url);
        this.$emit('copy')
      } catch (err) {
        console.error('Unable to copy to clipboard: ', err);
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleDialogOpen(value) {
      if (value) {
        // Dialog is opening, add a loading class
        this.isLoadingDiv = true;

        // Simulate delay for content rendering (adjust the delay as needed)
        setTimeout(() => {
          // Content is loaded, remove the loading class
          this.isLoadingDiv = false;
        }, 1000); // Adjust the delay as needed
      }
    },
    getPhoto() {
      const ft = this.dialogContent.photos[0]
      return ft.url ? ft.url : ft

    },
    emitOutside() {
      this.$emit('somevent')
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogContent: {
      type: Object,
      default: () => {
        ''
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
}
</script>

<style scoped>
.card-big {}

.card-mobile {
  background-color: rgba(255, 255, 255, 0.89) !important;
  height: 100%;
}

.arrowhead-big {
  position: absolute;
  top: calc(50% + 30px);
  right: calc(50% - 408px);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px;
  border-color: transparent transparent transparent #fff;
  z-index: 9999;
  /* Increase z-index value */
}

.arrowhead-md {
  position: fixed;
  top: 54%;
  right: calc(50% - 18px);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px;
  border-color: transparent transparent transparent #fff;
  z-index: 9999;
  /* Increase z-index value */
}


.dialog-divider {
  height: 5px;
  border: 8px solid #ee7317;
}

.img-icon {
  height: 32px;
  width: 32px;
}

.categorie-txt {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.0rem;
  color: #ee7317;
  font-weight: 400;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  padding-left: 8px;
}

.card-100 {
  min-height: 99vh;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-dialog__content {
  width: 49%;
}

/* Adjust width for screens bigger than 1621px */
@media (min-width: 1592px) {
  .v-dialog__content {
    width: 51%;
  }
}

/* Adjust width for screens bigger than 1700px */
@media (min-width: 1676px) {
  .v-dialog__content {
    width: 52%;
  }
}

@media (min-width: 1716px) {
  .v-dialog__content {
    width: 53%;
  }
}

@media (min-width: 1736px) {
  .v-dialog__content {
    width: 54%;
  }
}

@media (min-width: 1766px) {
  .v-dialog__content {
    width: 55%;
  }
}

@media (min-width: 1796px) {
  .v-dialog__content {
    width: 56%;
  }
}

@media (min-width: 1816px) {
  .v-dialog__content {
    width: 57%;
  }
}

@media (min-width: 1836px) {
  .v-dialog__content {
    width: 58%;
  }
}

@media (min-width: 1866px) {
  .v-dialog__content {
    width: 59%;
  }
}


body {
  --sb-track-color: #ffffff !important;
  --sb-thumb-color: #34b7e4 !important;
  --sb-size: 3px !important;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color) !important;
}

body::-webkit-scrollbar {
  width: var(--sb-size) !important
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color) !important;
  border-radius: 9px !important;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color) !important;
  border-radius: 9px !important;

}
.img-marker{
  transform: rotate(90deg);
  -webkit-transform: rotate(120deg);
}
</style>